import {
  FibreTypeMix,
  FIBRE_TYPE_MIX_SN,
  FIBRE_TYPE_MIX_NHS,
  FIBRE_TYPE_MIX_DEDUCT2,
  ESTIMATED_GENERAL_PRICING,
  FibreType
} from './fibreTypes';
import { qualityPrice } from './qualityTypes';
import {
  UPHOLSTERY_PRICING,
  maxImperfectionDiscount
} from './weightImperfectionsType';
import {
  Fabric,
  FabricAttributes as fa,
  isFieldVarianted,
  flattenObject
} from 'product-validator';
import { PRICE_LOOKUP } from './priceLookup';
import { MergedProduct } from '../../../types/formTypes';
import { ECO_TAGS, SPECIALTY_FABRICS } from '../../utils';
//import { KnitOrWoven } from 'product-validator/lib/attributes/fabricAttributes';

const KNIT_WEAVE_TYPES = flattenObject(fa.KnitWeaveType);
const OTHER_FIBRE_BASE_PRICING = 8;
const OTHER_GENERAL_BASE_PRICING = 7;

/**
 * Check if two FibreTypeMix objects are equal
 */
const isFibreTypeMixEqual = (
  obj1: FibreTypeMix,
  obj2: FibreTypeMix
): boolean => {
  if (obj1.primary === obj2.primary && obj1.secondary === obj2.secondary)
    return true;
  else return false;
};

/**
 * Check if a given FibreTypeMix object exists in an array of FibreTypeMix objects
 * @param givenFibreTypeMix (needle)
 * @param searchFibreTypeMix (haystack)
 */
const includesFibreTypeMix = (
  givenFibreTypeMix: FibreTypeMix,
  searchFibreTypeMix: FibreTypeMix[]
): boolean => {
  for (const fibreTypeMix of searchFibreTypeMix)
    if (isFibreTypeMixEqual(givenFibreTypeMix, fibreTypeMix)) return true;
  return false;
};

const getFibreInfo = (
  fibre: string,
  knitOrWoven: fa.KnitOrWoven,
  knitWeaveType: string
): { price: number; fibreType: FibreType | undefined } => {
  try {
    for (const fibreType of Object.keys(PRICE_LOOKUP)) {
      const fibres = PRICE_LOOKUP[fibreType as keyof typeof FibreType];

      if (fibre in fibres) {
        return {
          price: fibres[fibre][knitOrWoven][knitWeaveType],
          fibreType: fibreType as FibreType
        };
      } else if (
        (fibre !== fa.ContentGeneral.Natural ||
          fibre !== fa.ContentGeneral['Natural Blend'] ||
          fibre !== fa.ContentGeneral.Synthetic ||
          fibre !== fa.ContentGeneral['Synthetic Blend']) &&
        fibreType === 'Synthetic'
      ) {
        return {
          price: fibres['Other'][knitOrWoven][knitWeaveType],
          fibreType: fibreType as FibreType
        };
      }
    }

    throw new Error('Fibre is defined but not of 1/3 types of fibres.');
  } catch (err) {
    return {
      price: OTHER_FIBRE_BASE_PRICING,
      fibreType: undefined
    };
  }
};

const getStartingInfo = (
  primaryType: string,
  secondaryType: string | undefined,
  knitOrWoven: fa.KnitOrWoven,
  knitWeaveType: string
) => {
  // Determine the starting prices and the content group the fibre belongs to
  let { price: primaryPrice, fibreType: primaryGroup } = getFibreInfo(
    primaryType,
    knitOrWoven,
    knitWeaveType
  );

  let secondaryPrice = 0;
  let secondaryGroup = undefined;
  if (secondaryType) {
    ({ price: secondaryPrice, fibreType: secondaryGroup } = getFibreInfo(
      secondaryType,
      knitOrWoven,
      knitWeaveType
    ));
  }

  const fibreTypeMix: FibreTypeMix = {
    primary: primaryGroup,
    secondary: secondaryGroup
  };
  return { primaryPrice, secondaryPrice, fibreTypeMix };
};

const getFibres = (content: Fabric['content']) => {
  switch (content.contentType) {
    case fa.ContentType.EstimatedGeneral:
      return {
        primaryFibre: content.estimatedGeneralContent ?? '',
        secondaryFibre: undefined
      };
    case fa.ContentType.Known:
      return {
        primaryFibre: content.knownContent?.[0]?.content ?? '',
        secondaryFibre:
          content.knownContent && content.knownContent.length > 1
            ? content.knownContent[1].content
            : ''
      };
    case fa.ContentType.EstimatedSpecific:
      return {
        primaryFibre: content.estimatedSpecificContent?.primary ?? '',
        secondaryFibre: content.estimatedSpecificContent?.secondary
      };
  }
};

export const imperfectionsDiscount = (
  price: number,
  imperfections?: string[]
) => {
  let multiplier = 1;
  if (imperfections && imperfections.length > 0) {
    multiplier -= maxImperfectionDiscount(imperfections);
    price = Math.ceil(price * multiplier);
  }
  return price;
};

// see pricing changes task created november 2, 2021 for what exactly is being discounted
// *edit*: on february 12, 2025 the above task is being "undone" as a part of the task to increase
// price of fabrics by 10%.
export const universalPriceChange = (
  price: number,
  content: Fabric['content'],
  knitWeaveType: string | undefined
): number => {
  let priceChange = 1.1;
  // eco and specialty fabrics increase price by 10% (see utils.ts in server product utils folder for eco and specialty fabrics)
  let primaryFabricContent = content.estimatedSpecificContent?.primary;
  let secondaryFabricContent = content.estimatedSpecificContent?.secondary;

  if (
    primaryFabricContent &&
    (ECO_TAGS.includes(primaryFabricContent) ||
      SPECIALTY_FABRICS.includes(primaryFabricContent))
  ) {
    return price * priceChange;
  } else if (
    secondaryFabricContent &&
    (ECO_TAGS.includes(secondaryFabricContent) ||
      SPECIALTY_FABRICS.includes(secondaryFabricContent))
  ) {
    return price * priceChange;
  } else if (content.knownContent) {
    //checking if known percentage content has any ECO or Specialty Fabric Content
    for (let i = 0; i < content.knownContent?.length; i++) {
      if (
        ECO_TAGS.includes(content.knownContent[i].content) ||
        SPECIALTY_FABRICS.includes(content.knownContent[i].content)
      ) {
        return price * priceChange;
      }
    }
  }

  return price;
};

export const priceSuggestion = (
  content: Fabric['content'],
  knitOrWoven: fa.KnitOrWoven,
  knitWeaveType: string | undefined,
  bundleOrRoll: fa.BundleOrRoll,
  lengthM: number,
  pattern: string | undefined,
  weight: fa.Weight,
  uses: string[],
  quality: string | undefined,
  imperfections: string[] | undefined
): number => {
  let price = 0;
  // Extract the primary and secondary from the content object
  const { primaryFibre, secondaryFibre } = getFibres(content);

  // Determining if there is no knit or weave type associated with a general content type
  // There is no association when general content is selected and knitWeaveType is undefined
  // If there is no association (knit or weave type selected with a general content selected) then we use the const ESTIMATED_GENERAL_PRICING to determine the price
  // const ESTIMATED_GENERAL_PRICING is used instead of const PRICE_LOOKUP because there is no knit or weave type associated
  if (
    content.contentType === fa.ContentType.EstimatedGeneral &&
    !knitWeaveType
  ) {
    try {
      price = ESTIMATED_GENERAL_PRICING[primaryFibre];
      if (price === undefined) throw new Error('Other type selected');
    } catch (err) {
      price = OTHER_GENERAL_BASE_PRICING;
    }
  }

  // If knitWeaveType is not defined or is not a suggested thread Type,
  // then standard for Knit is Jersey and standard for Woven is Plain Weave
  if (!knitWeaveType || !KNIT_WEAVE_TYPES.includes(knitWeaveType)) {
    knitWeaveType =
      knitOrWoven === fa.KnitOrWoven.Knit
        ? fa.KnitWeaveType.Knit.Standard.Jersey
        : fa.KnitWeaveType.Woven.Standard['Plain Weave'];
  }

  // Get starting price and fibreTypeMix groups
  let { primaryPrice, secondaryPrice, fibreTypeMix } = getStartingInfo(
    primaryFibre,
    secondaryFibre,
    knitOrWoven,
    knitWeaveType
  );

  // Determine to use primary or secondary price
  // Primary can be a fibre or one of the estimated general types
  // if (content.contentType === fa.ContentType.EstimatedGeneral)
  //   price = getEstimatedGeneralPrice(primaryFibre, knitOrWoven, knitWeaveType);
  // else |if| <-- originally an else if (secondaryFibre && ...)
  if (secondaryFibre && isFibreTypeMixEqual(fibreTypeMix, FIBRE_TYPE_MIX_NHS)) {
    price = secondaryPrice;
  } else {
    price = price === 0 ? primaryPrice : price;

    // if price is undefined at this point, then set to be the default of $8
    if (price === undefined) {
      price = 8;
    }
  }

  // now that we have price we enter it through a 'UNIVERSAL PRICE CHANGE'
  // this is done because we want to increase our prices on Fabrics
  price = universalPriceChange(price, content, knitWeaveType);

  /*
   * If user has ONLY selected Common Uses with "Upholstery" and
   * has NOT selected a Weave Type of "Tapestry Weave" then
   * use special upholstery pricing.
   */
  if (
    weight &&
    uses.every(
      (use) => use.includes('Home Decor') || use.includes('Upholstery')
    ) &&
    knitWeaveType !== fa.KnitWeaveType.Woven.Heavy['Tapestry Weave']
  )
    price = UPHOLSTERY_PRICING[weight];

  // Alter price depending on fibreType combination
  if (isFibreTypeMixEqual(fibreTypeMix, FIBRE_TYPE_MIX_SN)) price += 2;
  else if (includesFibreTypeMix(fibreTypeMix, FIBRE_TYPE_MIX_DEDUCT2))
    price -= 2;

  // Alter price depending on quality
  price += quality
    ? qualityPrice(
        quality,
        primaryFibre,
        secondaryFibre,
        knitOrWoven,
        knitWeaveType
      )
    : 0;

  // Alter price depending on weight
  if (weight === fa.Weight.Heavy) price += 2;

  // Alter price depending on batting and weight
  if (knitOrWoven === fa.KnitOrWoven.Woven && knitWeaveType === 'Batting') {
    if (weight === fa.Weight.Light) price -= 1;
    else if (weight === fa.Weight.Heavy) price += 1;
  }

  // Alter price depending on pattern
  if (pattern && pattern !== 'None') price += 1;

  // Alter price depending on imperfections
  price = imperfectionsDiscount(price, imperfections);

  // Bundles sold in quantities of 1; multiply price per M by length
  if (bundleOrRoll === fa.BundleOrRoll.Bundle) price *= lengthM;

  // Round up to nearest 50 cents
  price = Math.ceil(price / 0.5) * 0.5;

  // Round to 1 decimal place
  // price = Math.round(price * 10) / 10;

  return price;
};

export const priceSuggestionFromForm = (fabric: MergedProduct) => {
  if (
    fabric.content &&
    fabric.knitOrWoven &&
    fabric.bundleOrRoll &&
    fabric.weight &&
    fabric.lengthM?.[0] &&
    fabric.uses
  ) {
    let length: string | number = fabric.lengthM[0];
    if (isFieldVarianted('lengthM', fabric.variantedFields)) length = 1;
    if (typeof length === 'string') length = parseFloat(length);
    if (typeof length !== 'number') return 0;

    let imperfections = fabric.imperfections?.[0];
    if (isFieldVarianted('imperfections', fabric.variantedFields))
      imperfections = undefined;

    return priceSuggestion(
      fabric.content,
      fabric.knitOrWoven,
      fabric.knitWeaveType,
      fabric.bundleOrRoll,
      length,
      fabric.pattern,
      fabric.weight,
      fabric.uses,
      fabric.quality,
      imperfections
    );
  }
  return undefined;
};
